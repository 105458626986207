<template>
  <div id="intro-example" class="p-5 text-center bg-image">
    <div class="mask" style="background-color: rgba(0, 0, 0, 0.7)">
      <div class="d-flex justify-content-center align-items-center h-100">
        <div class="text-white">
          <h1 class="mb-3">Welcome to Magic Studio!</h1>
          <h5 class="mb-4">Take the first step to the pinnacle of success!</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.cover-container {
  max-width: 70vw;
  padding-top: 10vh;
}

/* Default height for small devices */
#intro-example {
  height: 400px;
}

/* Height for devices larger than 992px */
@media (min-width: 992px) {
  #intro-example {
    height: 600px;
  }
}

.mask {
  border-radius: 5em;
}
</style>
