import { createRouter, createWebHistory } from 'vue-router';

import HomePage from './components/main/HomePage.vue';

// import { Component } from 'vue';
// const AdminPage: Component = () => import('./components/admin/AdminPage.vue');

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: HomePage, name: 'home' },
    { path: '/:notFound(.*)', redirect: '/' },
  ],
  linkActiveClass: 'active',
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return {
      left: 0,
      top: 0,
    };
  },
});

router.beforeEach((to, from, next) => {
  to.meta.from = from;
  next();
});

export default router;
