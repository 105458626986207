<template>
  <main class="container mx-auto py-4 position-relative">
    <router-view />
  </main>
</template>

<script lang="ts" setup></script>

<style scoped>
main {
  min-height: 65vh;
  max-width: 1024px;
}
</style>
