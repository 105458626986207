import { InjectionKey } from 'vue';
import { Store, createStore, useStore as baseUseStore } from 'vuex';

import {
  RootActions,
  RootGetters,
  RootMutations,
  RootState,
} from '@/types/store/root';

export const key: InjectionKey<Store<RootState>> = Symbol();

export function useStore() {
  return baseUseStore<RootState>(key);
}

export const store = createStore<RootState>({
  modules: {},
  state() {
    return {
      message: 'Welcom to ads2play',
    } as RootState;
  },
  mutations: {
    setMessage(state, message) {
      state.message = message;
    },
  } as RootMutations,
  actions: {
    async updateMessage(_context, payload) {
      return 'need update' + payload;
    },
  } as RootActions,
  getters: {
    message(state) {
      return state.message;
    },
  } as RootGetters,
});
