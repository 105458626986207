<template>
  <nav class="navbar sticky-top navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img src="/images/ads2play_logo.webp" alt="logo" height="42" />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
        ref="navBtn"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse"
        id="navbarNavAltMarkup"
        ref="navLinks"
      >
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <router-link class="nav-link" to="/"> Home </router-link>
          </li>
          <li class="nav-item" v-if="isAuthorized">
            <a class="nav-link" @click.prevent="logoutUser"> Logout </a>
          </li>
          <li class="nav-item" v-else>
            <router-link class="nav-link" id="login-btn" to="/projects">
              Login
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const navLinks = ref(null);
const navBtn = ref(null);

const isAuthorized = false;

const collapseNavbar = () => {
  const navBtnEl = navBtn.value;
  navBtnEl.offsetParent && navBtnEl.click();
};

const logoutUser = () => console.log('logout');

onMounted(() => {
  router.beforeEach(() => {
    if (navLinks.value.classList.contains('show')) {
      collapseNavbar();
    }
  });
});
</script>

<style scoped>
.nav-link {
  cursor: pointer;
}

.nav-link {
  padding: 0.25rem 0;
  font-weight: 700;
  color: #fff8;
  margin-left: 1rem;
  border-bottom: 0.25rem solid transparent;
}

.nav-link:hover {
  color: #fffa;
  border-bottom-color: #fff8;
}

.nav-link.active {
  color: #fff;
  border-bottom-color: white;
}
</style>
